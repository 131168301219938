import React, { useState, lazy, Suspense } from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { IconName, useStyles2, Tab, TabContent, TabsBar } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { css } from '@emotion/css';
// import { IncidentAdvisorContainer } from './IncidentAdvisor';
// import { SettingsContainer } from './Settings';
// import { TrainingHistoryContainer } from './TrainingHistory';
// import { TrainingZonesContainer } from './TrainingZoneMgmt';
import { ValveContainer } from './ValveMonitoring/ValveContainer';

const TrainingHistoryContainer = lazy(() => import('./TrainingHistory/TrainingHistoryContainer'));
const TrainingZonesContainer = lazy(() => import('./TrainingZoneMgmt/TrainingZonesContainer'));
const SettingsContainer = lazy(() => import('./Settings/SettingsContainer'));
const IncidentAdvisorContainer = lazy(() => import('./IncidentAdvisor/IncidentAdvisorContainer'));

export interface TabsContainerProps {}

export const TabsContainer: React.FunctionComponent<TabsContainerProps> = () => {
  const { state } = useAppState();
  const { dico, user, analysisProfile } = state;

  /**
   * Translation
   */

  const TR_INCIDENT_ADVISOR = dico['incident-advisor']?.toUpperCase();
  const TR_TRAINING_ZONES_MGMT = dico['training-zones-mgmt']?.toUpperCase();
  const TR_TRAINING_HISTORY = dico['training-history']?.toUpperCase();
  const TR_SETTINGS = dico.settings?.toUpperCase();
  const TR_VALVE = dico.valve;
  const TR_VALVE_MONITORING = dico['valve-monitoring']?.toUpperCase();

  const [stateTabs, setStateTabs] = useState<any[]>([]);
  const styles = useStyles2(getStyles);

  React.useEffect(() => {
    const currentTabs = [];
    if (analysisProfile) {
      currentTabs.push(
        {
          label: analysisProfile !== TR_VALVE ? TR_INCIDENT_ADVISOR : TR_VALVE_MONITORING,
          active: true,
          icon: 'eye' as IconName,
        },
        { label: TR_TRAINING_HISTORY, active: false, icon: 'table' as IconName }
      );

      // Add tabs for admin (training zone mgmt, settings)
      if (user.orgRole !== 'Viewer') {
        currentTabs.push({ label: TR_TRAINING_ZONES_MGMT, active: false, icon: 'calendar-alt' as IconName });
        currentTabs.push({ label: TR_SETTINGS, active: false, icon: 'sliders-v-alt' as IconName });
      }
    }
    setStateTabs(currentTabs);
  }, [analysisProfile]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <TabsBar className={styles.tabsContainer}>
        {stateTabs.length !== 0 &&
          stateTabs.map(({ label, active, icon }, index) => {
            return (
              <Tab
                key={`tab-${label}-${index}`}
                label={label}
                active={active}
                style={{
                  fontWeight: active ? 600 : 500,
                }}
                icon={icon}
                onChangeTab={() =>
                  setStateTabs(stateTabs.map((stateTab, idx) => ({ ...stateTab, active: idx === index })))
                }
              />
            );
          })}
      </TabsBar>
      {stateTabs.length !== 0 && (
        <TabContent className={styles.tabContent}>
          <Suspense fallback={'Loading...'}>
            {stateTabs[0].active &&
              analysisProfile &&
              (analysisProfile !== TR_VALVE ? <IncidentAdvisorContainer /> : <ValveContainer />)}
            {stateTabs[1].active && <TrainingHistoryContainer />}
            {user.orgRole !== 'Viewer' && stateTabs[2].active && <TrainingZonesContainer />}
            {user.orgRole !== 'Viewer' && stateTabs[3].active && <SettingsContainer />}
          </Suspense>
        </TabContent>
      )}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    tabContent: css`
      height: 100%;
      min-height: 514px;
      width: 100%;
      padding: 20px;
      background-color: ${theme.isDark ? '#222426' : '#fbfbfb'};
      min-width: 873px;
    `,
    tab: css`
      background-color: ${theme.isDark ? '#222426' : '#fbfbfb'};
      height: 100%;
    `,
    tabsContainer: css`
      overflow-x: clip;
    `,
  };
};
