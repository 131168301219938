import { GrafanaTheme2, PanelData, TimeRange } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { SimpleOptions } from 'app-context/types';
import { css } from '@emotion/css';
import {
  findIndexByPanelAlias,
  findIndexByPanelType,
  getDashboardUid,
  getOrderedIdCardElements,
  INCIDENT_ADVISOR_PLUGIN_NAME,
  updateSessionVariable,
} from 'helpers/dashboards';
import { translateDictionary } from 'helpers/dictionary';
import React, { useEffect } from 'react';
import { INFLUX_DATASOURCE_NAME, ISO_CLASS_URL, JSON_DATASOURCE_NAME, MYSQL_DATASOURCE_NAME } from 'constants/api';
import { getSessionQuery } from 'services/queries';
import { getDashboardAlerts, getDashboardByUid, getDatasource, getJson, mysqlRequest } from 'services/requests';
import { HeaderContainer } from 'components/Header/HeaderContainer';
import { LoadingTabsContainer } from './components/Tabs/LoadingTabsContainer';
import { InstantIndicatorsContainer } from './components/InstantIndicators/InstantIndicatorsContainer';
import { TabsContainer } from './components/Tabs/TabsContainer';

export interface MainContainerProps {
  data: PanelData;
  timeRange: TimeRange;
  options: SimpleOptions;
  timeZone: string;
}

const CALC_FAHRENHEIT = '* 9/5 + 32';

export const MainContainer: React.FunctionComponent<MainContainerProps> = ({ data, timeRange, options, timeZone }) => {
  const { dispatch, state } = useAppState();
  const { country, datasourceMysql, dico, isDicoLoading, macAddress, theme, identityCard, dashboard, selectedSession } =
    state;
  const styles = useStyles2(getStyles);

  const getDatasources = async () => {
    const mysqlDs = await getDatasource(MYSQL_DATASOURCE_NAME);
    const influxDs = await getDatasource(INFLUX_DATASOURCE_NAME);
    const dashGen = await getDatasource(JSON_DATASOURCE_NAME);

    dispatch({
      type: 'SET_DATASOURCES',
      payload: {
        mysql: mysqlDs,
        influx: influxDs,
        dashGen: dashGen,
      },
    });
  };

  const getTranslatedDictionary = async (langSelect: string) => {
    const translatedDictionary = await translateDictionary(langSelect);
    dispatch({ type: 'GET_DICO', payload: translatedDictionary });
  };

  const getIdentityCardElements = async () => {
    const uid = getDashboardUid();
    const dashboard = await getDashboardByUid(uid);
    dispatch({
      type: 'GET_DASHBOARD_INFOS',
      payload: { name: dashboard.title, tags: dashboard.tags, id: dashboard.id },
    });

    const incidentAdvisorPanelIndex = findIndexByPanelType(INCIDENT_ADVISOR_PLUGIN_NAME, dashboard?.panels);
    const incidentAdvisorPanel = dashboard?.panels[incidentAdvisorPanelIndex];
    const temperatureIndex = findIndexByPanelAlias(dico['ambiant-temperature'], incidentAdvisorPanel.targets);

    if (temperatureIndex !== -1) {
      if (incidentAdvisorPanel.targets[temperatureIndex].query.includes(CALC_FAHRENHEIT)) {
        dispatch({ type: 'SET_TEMPERATURE_UNIT', payload: 'fahrenheit' });
      }
    }

    const identityCardOrdered = getOrderedIdCardElements(dashboard, dico);
    dispatch({ type: 'SET_IDENTITY_CARD', payload: identityCardOrdered });
  };

  const getAlerts = async (dashboardId: number) => {
    const dashboardAlerts = await getDashboardAlerts(dashboardId);
    dispatch({ type: 'GET_ALERTS', payload: Array.isArray(dashboardAlerts) ? dashboardAlerts : [] });
  };

  const getCurrentSession = async () => {
    const currentAiSessionQuery = getSessionQuery(macAddress);
    const queryResult = await mysqlRequest(datasourceMysql.uid, currentAiSessionQuery);
    if (!queryResult.length) {
      return;
    }

    const sessionData = queryResult[0];
    dispatch({ type: 'SET_CURRENT_SESSION', payload: { id: sessionData[0], status: sessionData[1] } });
    // Select last session by default on plugin load and update session var
    if (!selectedSession.uiId || selectedSession.isLast) {
      updateSessionVariable(sessionData[0]);
      dispatch({
        type: 'SET_SELECTED_SESSION',
        payload: {
          id: sessionData[0],
          uiId: queryResult.length,
          status: sessionData[1],
          isLast: true,
        },
      });
    }
  };

  const getIsoClassifications = async () => {
    const isoClasses = await getJson(ISO_CLASS_URL);
    if (typeof isoClasses === 'object') {
      if (Object.keys(isoClasses).length) {
        dispatch({ type: 'SET_ISO_CLASSIFICATIONS', payload: isoClasses });
      }
    } else {
      console.log('Iso classification file not found!');
    }
  };

  const getTimeZone = () => {
    let timezoneToUse = timeZone;
    if (timeZone === 'browser' || timeZone === 'utc') {
      timezoneToUse = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    dispatch({ type: 'SET_TIMEZONE', payload: timezoneToUse });
  };

  useEffect(() => {
    getTimeZone();
  }, [timeZone]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getDatasources();
    getTranslatedDictionary(country);
    getIsoClassifications();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dashboard.id) {
      getAlerts(dashboard.id);
    }
  }, [dashboard, data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch({ type: 'GET_PANEL_DATA', payload: data.series });
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //@ts-ignore
    const statisticsQueryIndex = data?.request?.targets?.findIndex((d) => d.alias === 'Open / Closed statistics');
    if (statisticsQueryIndex !== -1) {
      //@ts-ignore
      const queryStatistics = data.request?.targets[statisticsQueryIndex].query;
      dispatch({ type: 'SET_STATISTICS_QUERY', payload: queryStatistics });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch({ type: 'GET_TIME_RANGE', payload: timeRange });
  }, [timeRange]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch({ type: 'SET_PANEL_OPTIONS', payload: options });
  }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.entries(dico).length !== 0) {
      dispatch({ type: 'SET_DICO_LOADING', payload: false });
      getIdentityCardElements();
    }
  }, [dico]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (datasourceMysql.uid) {
      getCurrentSession();
    }
  }, [datasourceMysql, data.request?.requestId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAnalysisProfile = () => {
    if (identityCard.length) {
      const porfileIndex = identityCard.findIndex((element) => element.name === dico['analysis-profile']);
      if (porfileIndex !== -1) {
        const profile = identityCard[porfileIndex].value;
        dispatch({ type: 'SET_ANALYSIS_PROFILE', payload: profile });
        return profile;
      }
      return '';
    }
    return '';
  };

  const ANALYSIS_PROFILE = React.useMemo(() => getAnalysisProfile(), [identityCard]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.mainContainer}>
      <HeaderContainer />
      <div className={styles.bottomSection}>
        {/* Gauges / Graph - Vibratory Severity & Shock Indicator || Rotation Speed*/}
        {ANALYSIS_PROFILE && ANALYSIS_PROFILE !== dico.valve && <InstantIndicatorsContainer />}
        {/* Tabs */}
        <div className={styles.bottomRightSection}>
          {isDicoLoading ? <LoadingTabsContainer theme={theme} /> : <TabsContainer />}
        </div>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    mainContainer: css`
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 15px;
      overflow: auto;
      max-height: 767px;
      background: ${theme.colors.background.canvas};
      padding: 8px;
    `,
    bottomSection: css`
      display: flex;
      flex-direction: row;
      width: 100%;
    `,
    bottomRightSection: css`
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 8px 0px 0px 8px;
    `,
  };
};
